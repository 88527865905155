/* Add CSS styles to adjust the banner appearance */
.banner-image {
  text-align: center;
}

.legal-overview {
  border-radius: 6px;
  /* margin-bottom: 3.5em; */
  padding: 1.6em 1.6em 0.6em;
}

@media (min-width: 769px) {
  .legal-content-section {
    font-size: 16px;
  }
}

@media (min-width: 1280px) {
  .heading--h5 {
    font-size: 23px;
  }
}

@media (min-width: 768px) {
  .heading--h5 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

ul li {
  list-style: none;
  margin-bottom: 10px;
}

.heading--h5 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  line-height: 1.1;
  text-align: center;
}

.terms-subheading {
  margin-top: 1.61em;
}

.mt-0 {
  margin-top: 0 !important;
}

.title {
  text-align: center;
  font-family: "Poppins";
  font-size: 4rem;
  font-weight: 600;
  color: black;
}

.sub {
  text-align: center;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 600;
  margin-top: -4rem;
  color: black;
}

#headtitle {
  font-size: 30px;
  font-family: Poppins;
}

.unique-title::after {
  content: "";
  height: 3px;
  background: #fbb900;
  width: 80px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 0.75em !important;
}
