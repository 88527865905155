body {
  background-image: url(https://cdnb.artstation.com/p/assets/images/images/003/704/285/large/dusica-neskovic-muzejdanfinal.jpg?1476637309);
}

h1,
h2,
h3,
h4,
h5,
h6 {
}
section {
  padding: 60px 0;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.site-heading h2 span {
  color: #ff5a6e;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.site-heading h4::before {
  background: #ff5a6e none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading h2 span {
  color: #ff5a6e;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.team-area .single-item {
  margin-bottom: 30px;
}

.team-area .item .thumb {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.team-area .item .thumb::after {
  background: #232323 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  width: 100%;
}

.team-area .team-items .item:hover .thumb::after {
  opacity: 0.7;
}

.team-area .item .thumb .overlay {
  top: -100%;
  left: 0;
  padding: 20px;
  position: absolute;
  text-align: center;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: 1;
}

.team-area .item:hover .thumb .overlay {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.team-area .item .thumb .overlay p {
  color: #ffffff;
}

.team-area .item .thumb .overlay h4 {
  color: #ffffff;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}

.team-area .item .thumb img {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.team-area .item:hover .thumb img {
  opacity: 0.6;
}

.team-area .item .thumb .social li {
  display: inline-block;
}

.team-area .item .thumb .social li a {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 0 2px;
  text-align: center;
  width: 40px;
}

.team-area .info {
  background: #ffffff none repeat scroll 0 0;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  padding: 40px 20px 20px;
  position: relative;
  text-align: center;
  z-index: 9;
}

.team-area .info .message {
  height: 50px;
  line-height: 40px;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}

.team-area .info .message a {
  background: #fff none repeat scroll 0 0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  -o-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  box-sizing: border-box;
  color: #ff5a6e;
  display: inline-block;
  font-size: 20px;
  height: 50px;
  line-height: 50px;
  width: 50px;
}

.team-area .info .message a i {
  font-weight: 500;
}

.team-area .info h4 {
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: capitalize;
}

.team-area .info span {
  color: #ff5a6e;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.team-area .social li.twitter a {
  background-color: #00b6f1;
}

.team-area .social li.pinterest a {
  background-color: #bd081c;
}

.team-area .social li.facebook a {
  background-color: #3b5998;
}

.team-area .social li.google-plus a {
  background-color: #df4a32;
}

.team-area .social li.vimeo a {
  background-color: #1ab7ea;
}

.team-area .social li.instagram a {
  background-color: #cd486b;
}
